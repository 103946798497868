import { createStyles } from '@mantine/core';

const useStyle = createStyles(theme => ({
  container: {
    maxWidth: '1100px',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing.xl * 8,
  },

  outer: {},

  subtitle: {
    fontWeight: 600,
  },

  inner: {
    display: 'flex',
    justifyContent: 'center',

    '&.full-height': {
      minHeight:
        'calc(100vh - var(--mantine-header-height) - var(--mantine-footer-height) - 48px)',
    },

    '&.column': {
      flexDirection: 'column',
      gap: '2rem',
    },
  },

  content: {
    maxWidth: 530,
    marginRight: theme.spacing.xl * 2,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
      marginRight: 0,
    },
  },

  title: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.gray[0]
        : theme.colors.gray[9],
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 44,
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan('xs')]: {
      fontSize: 28,
    },
  },

  button_group: {
    display: 'flex',
  },

  control: {
    [theme.fn.smallerThan('xs')]: {
      flex: 1,

      '&.explore': {
        width: '100%',
        flex: 'unset'
      },
    },
  },

  image: {
    paddingRight: '25px',
    margin: 'auto',
  },
  imageHst: {
    paddingRight: '25px',
    margin: 'auto',
    width: '115%',
  },

  illustration: {
    width: '100%',
    paddingLeft: theme.spacing.xl * 2,
    margin: 'auto',
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  imageContainer: {
    display: 'flex',
    padding: '30px 10px',
  },

  highlight: {
    position: 'relative',
    backgroundColor: theme.fn.variant({
      variant: 'light',
      color: theme.primaryColor,
    }).background,
    borderRadius: theme.radius.sm,
    padding: '4px 12px',
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default useStyle;
