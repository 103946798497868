import React from 'react';
import {
  Image,
  Container,
  Title,
  Button,
  Group,
  Text,
  useMantineColorScheme,
  Loader,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import ProjectRatings from '../../components/projects-ratings/ProjectsRatings';
import {
  RATING_TABLE_VISIBLE,
  APPLICATION_FORM_LINK,
  REQUEST_UPDATE_FORM_LINK,
} from '../../utils/constants';

import hdaLogoBlack from '../../assets/hdaLogo.png';
import hdaLogoWhite from '../../assets/hdaLogo_w.png';
import hstLogo from '../../assets/hstLogo.svg';
import illustration from '../../assets/illustration.svg';

import useStyle from './LandingPage.style';
import { useGetProjectsQuery } from '../../APIs/Aws.api';
import { SentinelProject } from '../../sentinel.types';

export default function LandingPage() {
  const { cx, classes } = useStyle();
  const { colorScheme } = useMantineColorScheme();
  const hdaLogo = colorScheme === 'dark' ? hdaLogoWhite : hdaLogoBlack;

  const { data: projects, isLoading } = useGetProjectsQuery();

  const lastReviewedProjects = projects
    ? [...projects]
        .sort(
          (a: SentinelProject, b: SentinelProject) =>
            b.reviewDate - a.reviewDate
        )
        .slice(0, 10)
    : [];

  return isLoading ? (
    <Loader className={classes.loader} />
  ) : (
    <div className={classes.outer}>
      <Container className={classes.container}>
        <div className={cx(classes.inner, 'full-height')}>
          <div className={classes.content}>
            <Title className={classes.title}>
              Project <span className={classes.highlight}>Sentinel</span>
            </Title>
            <Text mt={15} className={classes.subtitle}>
              Hedera Project & Token Rating System
            </Text>

            <Text size="sm" mt={15}>
              Compiled by independent experts and vetted by ecosystem partners
            </Text>

            <div className={classes.imageContainer}>
              <Image
                src={hstLogo}
                className={classes.image}
                alt="HeadStarter Logo"
              />
              <Image
                src={hdaLogo}
                className={classes.image}
                alt="Hedera DeFi Alliance Logo"
              />
            </div>

            <Text size="sm">
              The rating methodology offers an objective & procedural metric
              system for web3 projects & their associated tokens, Fungible &
              non-Fungible
            </Text>

            <Group mt={30} className={classes.button_group}>
              <Button
                radius="xl"
                size="sm"
                component={Link}
                className={cx('explore', classes.control)}
                to="/methodology"
              >
                Explore methodology
              </Button>
              <Button
                component="a"
                variant="default"
                radius="xl"
                size="sm"
                className={classes.control}
                href={APPLICATION_FORM_LINK}
                target="_blank"
              >
                Apply for review
              </Button>
              <Button
                component="a"
                variant="default"
                radius="xl"
                size="sm"
                className={classes.control}
                href={REQUEST_UPDATE_FORM_LINK}
                target="_blank"
              >
                Request update
              </Button>
            </Group>
          </div>
          <Image
            src={illustration}
            className={classes.illustration}
            alt={'Landing Page Illustration'}
          />
        </div>
        {RATING_TABLE_VISIBLE && (
          <div className={cx(classes.inner, 'column')}>
            <Title className={classes.title}>
              Latest Reviewed{' '}
              <span className={classes.highlight}>Projects</span>
            </Title>
            {lastReviewedProjects && lastReviewedProjects.length > 0 && (
              <ProjectRatings data={lastReviewedProjects} />
            )}
            <Button mt="md" component={Link} to="/tokens" variant="outline">
              See All Projects
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
}
